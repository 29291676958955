
.search {
    width: 250px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 30px;
  margin-top: 30px;
}
 



    
  